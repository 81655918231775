import React from 'react';
import { Global, css } from '@emotion/core';
import Helmet from 'react-helmet';
import Header from '../components/header';
import useSiteMetadata from '../hooks/use-sitemetadata';
const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Content Creator Insights - by CreatorStack</title>
        <meta name="description" content="" />
      </Helmet>
      <Header />
      <main
        css={css`
          max-width: 90vw;
        `}
      >
        {children}
      </main>
    </>
  );
};

export default Layout;
